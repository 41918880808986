import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.$supportEmail = "support@mallocdata.com";
Vue.prototype.$baseUrl = "https://www.mallocdata.com";

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
