import Vue from 'vue'
import Router from 'vue-router'

const Home = () => import(/* webpackChunkName: "home" */ './components/Home' );
const Privacy = () => import(/* webpackChunkName: "privacy" */ './components/Privacy' );
const TermOfUse = () => import(/* webpackChunkName: "term" */ './components/TermOfUse' );

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'BC Real Estate Data Analytics Solutions - Malloc Data Inc.',
                requiresLogin: false,
            },
            props: {
                default: true,
                currentRoute: 'home'
            }
        },
        {
            path: '/privacy-policy',
            name: 'privacyPolicy',
            component: Privacy,
            meta: {
                title: 'Privacy Policy',
                requiresLogin: false,
            }
        },
        {
            path: '/term-of-use',
            name: 'termOfUse',
            component: TermOfUse,
            meta: {
                title: 'Term of Use',
                requiresLogin: false,
            }
        },
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})
